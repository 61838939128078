var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"dashboard__container--body--col pt-3 mb-0",staticStyle:{"width":"100%"}},[_c('button',{staticClass:"btn btn__primary",on:{"click":function($event){return _vm.updateIds()}}},[_vm._v("Update Ids")]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.evereeUsersWithoutExternalId,"styleClass":"vgt-table striped","search-options":{
            enabled: true,
            placeholder: 'Search this table',
          },"pagination-options":{
            enabled: true,
            mode: 'records',
            perPage: 10,
          }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }